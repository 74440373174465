<template>
    <div class="agreement">
        <div class="info" v-html="info"></div>
        <div class="bit" @click="goBack()">
            我已阅读,返回
        </div>
    </div>
</template>
<script>
import {getAgreement} from "@api/tasting";
export default {
  name: "canvass",
  data: function() {
    return {
        id:0,
        info:[]
    };
  },
  components: {
      
   },
    created() {
        this.id=this.$route.query.id;
    },
  mounted: function() {
      this.getAgreement();
  },
  methods: {
      getAgreement:function(){
        getAgreement({
            id:this.id,
        }).then(res=>{
            this.info = res.data.agreement;
        })
      },
      goBack:function(){
          this.$router.back(-1);
      }
  }
};
</script>
<style scoped lang="scss">
.agreement{
    text-align: center;
    .info{
        margin-bottom: 3rem;
    }
    .bit{
        position: fixed;
        bottom: 0.6rem;
        color: #fff;
        width: 6rem;
        padding: 0.2rem 1.2rem;
        background-color: #f00;
        border-radius: 0.1rem;
        right: -3rem;
        margin-right: 50%;
    }
}
</style>